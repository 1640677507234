// このプロジェクトではコントローラごとのJSファイルを個別に作成し、
// 共通処理としてこのファイルを require する構造とする。
// 業務ロジックはこのファイルに書かないこと。

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// --- BootstrapVue ---
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Application Style Sheets
import '../stylesheets/application'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// --- application scripts ---
import flashNotification from '../src/flash_notification'
flashNotification.start()

import VueNavbar from '../src/navbar'
VueNavbar.start()

import VueAppManager from '../src/vue_app_manager'
VueAppManager.start()

document.addEventListener('DOMContentLoaded', () => {
    // FIXME: Ajax エラーハンドラは axios 版に書き換え、src 配下に独立させる
    // $(".loading").bind("ajaxSend", () => {
    //     $(this).show();
    // }).bind("ajaxComplete", () => {
    //     $(this).hide();
    // });
    //
    // $(document).ajaxError((event, xhr, settings, thrownError) => {
    //     const log = {
    //         message: xhr.status >= 500 ? 'サーバーエラーが発生しました。' : '通信エラーが発生しました。'
    //     }
    //     if (xhr) {
    //         log.status = xhr.status;
    //         log.response = xhr.responseJSON;
    //     }
    //     console.log(log)
    //     alert(log.message);
    // });
});