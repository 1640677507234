import Vue from 'vue';
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

export default {
    start() {
        document.addEventListener('DOMContentLoaded', () => {
            const elemId = 'flash-notifications'
            const elem = document.getElementById(elemId)
            if (!elem) return

            const vueApp = new Vue({
                el: `#${elemId}`,
                data() { return { } },
                methods: {
                    showToast() {
                        for (var i=0; i<Number(elem.dataset.count); i++) {
                            this.$bvToast.show(`flash-toast${i}`)
                        }
                    }
                },
            });
            setTimeout(()=> vueApp.showToast(), 300)
        });
    },
}
