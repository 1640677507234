import Vue from 'vue';

export default {
    start() {
        document.addEventListener('DOMContentLoaded', () => {
            const elemId = 'vue-navbar'
            const elem = document.getElementById(elemId)
            if (!elem) return

            new Vue({
                el: `#${elemId}`,
                data() { return { } },
            });
        });
    },
}
