import Vue from 'vue';
import merge from 'deepmerge/dist/umd.js';

let vueApp: Vue = null;

let vueAppOption = {
    el: '#content-root',
    data() {
        return {
        }
    },
}

export default {
    declareVue(opt) {
        vueAppOption = merge(vueAppOption, opt)
    },
    start() {
        document.addEventListener('DOMContentLoaded', () => {
            const elem = document.getElementById(vueAppOption.el.replace('#', ''));
            if (!elem) return;

            vueApp = new Vue(vueAppOption);
        });
    },
    getVue() { return vueApp },
}
